const SHOP_DATA = [
  {
    title: "Female",
    items: [
      {
        id: 1,
        name: "M Printed Gray Sweatshirt",

        img: [
          "./assets/Woman/1/9817661866014.jpg",
          "./assets/Woman/1/9817663242270.jpg",
          "./assets/Woman/1/9817663307806.jpg",
          "./assets/Woman/1/9817663864862.jpg",
          "./assets/Woman/1/9817666191390.jpg",
          "./assets/Woman/1/9817669042206.jpg",
        ],
        price: 25,
        header_img: "./assets/Woman/1/9817663864862.jpg",
      },
      {
        id: 2,
        name: "White Athlete",

        img: [
          "./assets/Woman/2/9784915230750.jpg",
          "./assets/Woman/2/9784915886110.jpg",
          "./assets/Woman/2/9784916049950.jpg",
          "./assets/Woman/2/9784916377630.jpg",
          "./assets/Woman/2/9784916508702.jpg",
          "./assets/Woman/2/9784916574238.jpg",
        ],
        price: 18,
        header_img: "./assets/Woman/2/9784915230750.jpg",
      },
      {
        id: 3,
        name: "Brenda Printed Jean Jacket",
        img: [
          "./assets/Woman/3/9833665527838.jpg",
          "./assets/Woman/3/9833665560606.jpg",
          "./assets/Woman/3/9833665724446.jpg",
          "./assets/Woman/3/9833666084894.jpg",
          "./assets/Woman/3/9833668673566.jpg",
          "./assets/Woman/3/9833668739102.jpg",
        ],
        price: 35,
        header_img: "./assets/Woman/3/9833668739102.jpg",
      },
      {
        id: 4,
        name: "Navy Blue Shoulder Bag",
        img: [
          "./assets/Woman/4/1910096-28417_image_1.jpg_Default-MainProductImage.jpg",
          "./assets/Woman/4/9846154199070.jpg",
          "./assets/Woman/4/9846154887198.jpg",
          "./assets/Woman/4/9846157213726.jpg",
          "./assets/Woman/4/9846157246494.jpg",
        ],
        price: 25,
        header_img:
          "./assets/Woman/4/1910096-28417_image_1.jpg_Default-MainProductImage.jpg",
      },
      {
        id: 5,
        name: "Jacquard Black and White Blouse",
        img: [
          "./assets/Woman/5/1610766-82586_image_1.jpg_Default-MainProductImage.jpg",
          "./assets/Woman/5/1610766-82586_image_2 (1).jpg_Default-MainProductImage.jpg",
          "./assets/Woman/5/1610766-82586_image_3 (1).jpg_Default-MainProductImage.jpg",
          "./assets/Woman/5/1610766-82586_image_4.jpg_Default-MainProductImage.jpg",
          "./assets/Woman/5/1610766-82586_image_5.jpg_Default-MainProductImage.jpg",
          "./assets/Woman/5/1610766-82586_image_6.jpg_Default-MainProductImage.jpg",
        ],
        price: 18,
        header_img:
          "./assets/Woman/5/1610766-82586_image_1.jpg_Default-MainProductImage.jpg",
      },
      {
        id: 6,
        name: "Ruffle Detailed White T-Shirt",
        img: [
          "./assets/Woman/6/1610750-80194_image_2.jpg",
          "./assets/Woman/6/1610750-80194_image_3.jpg",
          "./assets/Woman/6/1610750-80194_image_4.jpg",
          "./assets/Woman/6/1610750-80194_image_5.jpg",
          "./assets/Woman/6/1610750-80194_image_6.jpg",
          "./assets/Woman/6/1610750-80194.jpg",
        ],
        price: 14,
        header_img: "./assets/Woman/6/1610750-80194_image_2.jpg",
      },
      {
        id: 7,
        name: "Hooded White Basic Sweatshirt",
        img: [
          "./assets/Woman/7/167299-70000_image_1.jpg",
          "./assets/Woman/7/167299-70000_image_2.jpg",
          "./assets/Woman/7/167299-70000_image_3.jpg",
          "./assets/Woman/7/167299-70000_image_4.jpg",
          "./assets/Woman/7/167299-70000_image_5.jpg",
          "./assets/Woman/7/167299-70000_image_6.jpg",
        ],
        price: 18,
        header_img: "./assets/Woman/7/167299-70000_image_1.jpg",
      },
      {
        id: 8,
        name: "Black pants",
        img: [
          "./assets/Woman/8/9833664610334.jpg",
          "./assets/Woman/8/9833665069086.jpg",
          "./assets/Woman/8/9833665134622.jpg",
          "./assets/Woman/8/9833665298462.jpg",
          "./assets/Woman/8/9833665495070.jpg",
          "./assets/Woman/8/9833665921054.jpg",
        ],
        price: 14,
        header_img: "./assets/Woman/8/9833665495070.jpg",
      },
      {
        id: 9,
        name: "Blue Logo Printed Hoodie Red Sweatshirt",
        img: [
          "./assets/Woman/9/1600361-70456_image_1.jpg",
          "./assets/Woman/9/1600361-70456_image_2.jpg",
          "./assets/Woman/9/1600361-70456_image_3.jpg",
          "./assets/Woman/9/1600361-70456_image_4.jpg",
          "./assets/Woman/9/1600361-70456_image_5.jpg",
          "./assets/Woman/9/1600361-70456_image_6.jpg",
        ],
        price: 16,
        header_img: "./assets/Woman/9/1600361-70456_image_1.jpg",
      },
      {
        id: 10,
        name: "Leaf Printed Green Dress",
        img: [
          "./assets/Woman/10/9803428986910.jpg",
          "./assets/Woman/10/9803429838878.jpg",
          "./assets/Woman/10/9803430658078.jpg",
          "./assets/Woman/10/9803431116830.jpg",
          "./assets/Woman/10/9803431182366.jpg",
          "./assets/Woman/10/9803433279518.jpg",
        ],
        price: 16,
        header_img: "./assets/Woman/10/9803428986910.jpg",
      },
      {
        id: 30,
        name: "Polo Neck White Dress",
        img: [
          "./assets/Woman/11/1310120-620_image_2.jpg",
          "./assets/Woman/11/1310120-620_image_3.jpg",
          "./assets/Woman/11/1310120-620_image_4.jpg",
          "./assets/Woman/11/1310120-620_image_5.jpg",
          "./assets/Woman/11/1310120-620_image_6.jpg",
          "./assets/Woman/11/1310120-620_image_header.jpg",
        ],
        price: 17,
        header_img: "./assets/Woman/11/1310120-620_image_header.jpg",
      },
      {
        id: 31,
        name: "Patterned Pink Dress",
        img: [
          "./assets/Woman/12/9803433246750.jpg",
          "./assets/Woman/12/9803433410590_header.jpg",
          "./assets/Woman/12/9803433508894.jpg",
          "./assets/Woman/12/9803434491934.jpg",
          "./assets/Woman/12/9803434655774.jpg",
          "./assets/Woman/12/9803436359710.jpg",
        ],
        price: 17,
        header_img: "./assets/Woman/12/9803433410590_header.jpg",
      },
    ],
  },
  {
    title: "Male",
    items: [
      {
        id: 10,
        name: "Jake Sky Blue Street Comfort Jeans",
        img: [
          "./assets/Men/1/0042235639_image_1.jpg",
          "./assets/Men/1/0042235639_image_2.jpg",
          "./assets/Men/1/0042235639_image_3.jpg",
          "./assets/Men/1/0042235639_image_4.jpg",
          "./assets/Men/1/0042235639_image_5.jpg",
          "./assets/Men/1/0042235639_image_6.jpg",
        ],
        price: 220,
        header_img: "./assets/Men/1/0042235639_image_1.jpg",
      },
      {
        id: 11,
        name: "Crew Neck White T-Shirt",
        img: [
          "./assets/Men/2/9761661288478.jpg",
          "./assets/Men/2/9761661386782.jpg",
          "./assets/Men/2/9761666367518.jpg",
          "./assets/Men/2/9761666564126.jpg",
          "./assets/Men/2/9761666891806.jpg",
          "./assets/Men/2/9761667285022.jpg",
        ],
        price: 280,
        header_img: "./assets/Men/2/9761666891806.jpg",
      },
      {
        id: 12,
        name: "Tim Sky Blue Street Comfort Jean Shorts",
        img: [
          "./assets/Men/3/9784874762270.jpg",
          "./assets/Men/3/9784874795038.jpg",
          "./assets/Men/3/9784877121566.jpg",
          "./assets/Men/3/9784877252638.jpg",
          "./assets/Men/3/9784877547550.jpg",
          "./assets/Men/3/9784877613086.jpg",
        ],
        price: 110,
        header_img: "./assets/Men/3/9784874795038.jpg",
      },
      {
        id: 13,
        name: "Single Pocket Black Shirt",
        img: [
          "./assets/Men/4/9785013534750.jpg",
          "./assets/Men/4/9785013665822.jpg",
          "./assets/Men/4/9785013731358.jpg",
          "./assets/Men/4/9785016647710.jpg",
          "./assets/Men/4/9785016811550.jpg",
          "./assets/Men/4/9785022316574.jpg",
        ],
        price: 160,
        header_img: "./assets/Men/4/9785013665822.jpg",
      },
      {
        id: 14,
        name: "Blue Logo Printed Black Sweatshirt",
        img: [
          "./assets/Men/5/066303-900_image_1.jpg",
          "./assets/Men/5/066303-900_image_2.jpg",
          "./assets/Men/5/066303-900_image_3.jpg",
          "./assets/Men/5/066303-900_image_4.jpg",
          "./assets/Men/5/066303-900_image_5.jpg",
          "./assets/Men/5/066303-900_image_6.jpg",
        ],
        price: 160,
        header_img: "./assets/Men/5/066303-900_image_1.jpg",
      },
      {
        id: 15,
        name: "Blue Logo Printed Red T-Shirt",
        img: [
          "./assets/Men/6/065168-70468_image_1.jpg",
          "./assets/Men/6/065168-70468_image_2.jpg",
          "./assets/Men/6/065168-70468_image_3.jpg",
          "./assets/Men/6/065168-70468_image_4.jpg",
          "./assets/Men/6/065168-70468_image_5.jpg",
          "./assets/Men/6/065168-70468_image_6.jpg",
        ],
        price: 160,
        header_img: "./assets/Men/6/065168-70468_image_1.jpg",
      },
      {
        id: 16,
        name: "Blue Logo Printed Red T-Shirt",
        img: [
          "./assets/Men/7/065781-33099_image_1.jpg",
          "./assets/Men/7/065781-33099_image_2.jpg",
          "./assets/Men/7/065781-33099_image_3.jpg",
          "./assets/Men/7/065781-33099_image_4.jpg",
          "./assets/Men/7/065781-33099_image_5.jpg",
          "./assets/Men/7/065781-33099_image_6.jpg",
        ],
        price: 190,
        header_img: "./assets/Men/7/065781-33099_image_1.jpg",
      },
      {
        id: 17,
        name: "Freedom of Space X Blue Hoodie Denim Patch Pink Sweatshirt",
        img: [
          "./assets/Men/8/9780310900766.jpg",
          "./assets/Men/8/9780310999070.jpg",
          "./assets/Men/8/9780314472478.jpg",
          "./assets/Men/8/9780314505246.jpg",
          "./assets/Men/8/9780315324446.jpg",
          "./assets/Men/8/9780316045342.jpg",
        ],
        price: 200,
        header_img: "./assets/Men/8/9780310900766.jpg",
      },
      {
        id: 18,
        name: "Blue Printed Green T-Shirt",
        img: [
          "./assets/Men/9/9850503725086.jpg",
          "./assets/Men/9/9850503790622.jpg",
          "./assets/Men/9/9850503888926.jpg",
          "./assets/Men/9/9850503921694.jpg",
          "./assets/Men/9/9850504216606.jpg",
          "./assets/Men/9/9850504445982.jpg",
        ],
        price: 200,
        header_img: "./assets/Men/9/9850503725086.jpg",
      },
      {
        id: 19,
        name: "Blue Logo Printed Blue T-Shirt",
        img: [
          "./assets/Men/10/9846011985950.jpg",
          "./assets/Men/10/9846015623198.jpg",
          "./assets/Men/10/9846015754270.jpg",
          "./assets/Men/10/9846015787038.jpg",
          "./assets/Men/10/9846015885342.jpg",
          "./assets/Men/10/9846018703390.jpg",
        ],
        price: 200,
        header_img: "./assets/Men/10/9846011985950.jpg",
      },
      {
        id: 33,
        name: "Blue shirt",
        img: [
          "./assets/Men/11/9600164069406.jpg",
          "./assets/Men/11/9600166887454.jpg",
          "./assets/Men/11/9600169705502.jpg",
          "./assets/Men/11/9600170360862.jpg",
          "./assets/Men/11/9600170754078_header.jpg",
          "./assets/Men/11/9600173506590.jpg",
        ],
        price: 200,
        header_img: "./assets/Men/11/9600170754078_header.jpg",
      },
      {
        id: 34,
        name: "Minions Printed Black T-Shirt",
        img: [
          "./assets/Men/12/9761667743774.jpg",
          "./assets/Men/12/9761667809310.jpg",
          "./assets/Men/12/9761674035230.jpg",
          "./assets/Men/12/9761674231838.jpg",
          "./assets/Men/12/9761675378718.jpg",
          "./assets/Men/12/9761676197918.jpg",
        ],
        price: 200,
        header_img: "./assets/Men/12/9761675378718.jpg",
      },
    ],
  },
  {
    title: "Kids",
    items: [
      {
        id: 20,
        name: "Yellow Basic T-Shirt",
        img: [
          "./assets/Baby/1/9828197826590.jpg",
          "./assets/Baby/1/9828198088734.jpg",
          "./assets/Baby/1/9828199071774.jpg",
        ],
        price: 125,
        header_img: "./assets/Baby/1/9828197826590.jpg",
      },
      {
        id: 21,
        name: "Pink Basic T-Shirt",
        img: [
          "./assets/Baby/2/9828195467294.jpg",
          "./assets/Baby/2/9828197892126.jpg",
          "./assets/Baby/2/9828197990430.jpg",
        ],
        price: 90,
        header_img: "./assets/Baby/2/9828197892126.jpg",
      },
      {
        id: 22,
        name: "Blue Logo Printed White T-Shirt",
        img: [
          "./assets/Baby/3/9828202283038.jpg",
          "./assets/Baby/3/9828202479646.jpg",
          "./assets/Baby/3/9828202774558.jpg",
        ],
        price: 90,
        header_img: "./assets/Baby/3/9828202479646.jpg",
      },
      {
        id: 23,
        name: "Istanbul Printed Pale Lilac T-Shirt",
        img: [
          "./assets/Baby/4/6610033-81079_image_1.jpg",
          "./assets/Baby/4/6610033-81079_image_2.jpg",
          "./assets/Baby/4/6610033-81079_image_3.jpg",
        ],
        price: 165,
        header_img: "./assets/Baby/4/6610033-81079_image_1.jpg",
      },
      {
        id: 24,
        name: "Blue Logo Printed Dark Red T-Shirt",
        img: [
          "./assets/Baby/5/6610043-70468_image_1.jpg",
          "./assets/Baby/5/6610043-70468_image_2.jpg",
          "./assets/Baby/5/6610043-70468_image_3.jpg",
        ],
        price: 185,
        header_img: "./assets/Baby/5/6610043-70468_image_1.jpg",
      },
      {
        id: 25,
        name: "Printed Green T-Shirt",
        img: [
          "./assets/Baby/6/9828201365534.jpg",
          "./assets/Baby/6/9828201562142.jpg",
          "./assets/Baby/6/9828201693214.jpg",
        ],
        price: 185,
        header_img: "./assets/Baby/6/9828201365534.jpg",
      },
      {
        id: 26,
        name: "Bat Printed T-Shirt",
        img: [
          "./assets/Baby/7/9828194418718.jpg",
          "./assets/Baby/7/9828194451486.jpg",
          "./assets/Baby/7/9828194484254.jpg",
        ],
        price: 185,
        header_img: "./assets/Baby/7/9828194484254.jpg",
      },
      {
        id: 27,
        name: "Tweety Printed Sweatshirt",
        img: [
          "./assets/Baby/8/9645559087134.jpg",
          "./assets/Baby/8/9645559676958.jpg",
          "./assets/Baby/8/9645563609118.jpg",
        ],
        price: 185,
        header_img: "./assets/Baby/8/9645559676958.jpg",
      },
      {
        id: 28,
        name: "Batman Printed Black Sweatshirt",
        img: [
          "./assets/Baby/9/9692032335902.jpg",
          "./assets/Baby/9/9692032532510.jpg",
          "./assets/Baby/9/9692035252254.jpg",
        ],
        price: 185,
        header_img: "./assets/Baby/9/9692035252254.jpg",
      },
      {
        id: 29,
        name: "Tom Gray Jeans",
        img: [
          "./assets/Baby/10/9734593740830.jpg",
          "./assets/Baby/10/9734594199582.jpg",
          "./assets/Baby/10/9734597738526.jpg",
          "./assets/Baby/10/9734597967902.jpg",
        ],
        price: 185,
        header_img: "./assets/Baby/10/9734593740830.jpg",
      },
      {
        id: 35,
        name: "Enjoy Printed Navy Blue T-Shirt",
        img: [
          "./assets/Baby/12/9828197924894_h.jpg",
          "./assets/Baby/12/9828198055966.jpg",
          "./assets/Baby/12/9828198383646.jpg",
        ],
        price: 185,
        header_img: "./assets/Baby/12/9828197924894_h.jpg",
      },
      {
        id: 36,
        name: "Pink Sweatshirt",
        img: [
          "./assets/Baby/11/9854517411870.jpg",
          "./assets/Baby/11/9854517542942_header.jpg",
          "./assets/Baby/11/9854518231070.jpg",
        ],
        price: 185,
        header_img: "./assets/Baby/11/9854517542942_header.jpg",
      },
    ],
  },
];

export default SHOP_DATA;
